<template>
  <div class="graduateRegister" ref="container">
    <div class="tab">
      <div class="tabItem" :class="[{'active':activeTab==1}]" @click="toModle(1)">基础信息</div>
      <div class="tabItem" :class="[{'active':activeTab==2}]" @click="toModle(2)">经历及家庭</div>
      <div class="tabItem" :class="[{'active':activeTab==3}]" @click="toModle(3)">资料填写</div>
    </div>
    <div class="graduateRegisterMain" v-if="registerInfo&&personInfo">
      <div class="aduit-area per-area" v-if="registerInfo.auditStatus==1||registerInfo.auditStatus==2">
        <div class="module-head">
          <div class="module-head-title">审核信息</div>
          <div class="module-head-cont">
            <van-cell-group class="mycell-group">
              <van-field class="myfield" label="提交时间" :value="timestampReturnDate(registerInfo.auditInfo.submitTime,'timeYMDHM')" readonly />
              <van-field class="myfield" label="审核状态" :value="auditStatus[registerInfo.auditInfo.auditStatus]" readonly />
              <van-field class="myfield" label="审核时间" :value="timestampReturnDate(registerInfo.auditInfo.auditTime,'timeYMDHM')" readonly />
              <van-field class="myfield" label="审核意见" :value="registerInfo.auditInfo.auditOpinion" readonly />
            </van-cell-group>
          </div>
        </div>
      </div>
      <div class="image-area per-area">
        <div class="module-head">
          <div class="module-head-title">毕业照片</div>
          <div class="module-head-cont">
            <img class="head-img" v-if="personInfo.graduationUrl"  :src="personInfo.graduationUrl" alt="">
            <span v-else>暂无</span>
          </div>
        </div>
      </div>
      <div class="info-area per-area">
        <div class="module-head">
          <van-cell-group class="mycell-group">
            <van-field class="myfield" label="姓名" v-model="personInfo.name" readonly />
            <van-field class="myfield" label="曾用名" v-model="personInfo.formerName"  :placeholder="onlyRead?'暂无':'请输入'" :disabled="onlyRead"/>
            <van-field class="myfield" label="性别" v-model="sexData[personInfo.sex]" readonly  :placeholder="'暂无'"/>
            <van-field class="myfield" label="民族" v-model="personInfo.nation" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="政治面貌" v-model="personInfo.politicsStatus" :placeholder="'暂无'" readonly />
            <van-field class="myfield" label="证件号" v-model="personInfo.cardId" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="出生日期" :value="timestampReturnDate(personInfo.dateBirth)" readonly  :placeholder="'暂无'"/>
            <van-field class="myfield" label="手机号码" v-model="personInfo.phone" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学习形式" v-model="personInfo.stuTypeName" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="电子邮箱" v-model="personInfo.email" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="批次" v-model="personInfo.admissionBatch" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="入学时间" :value="timestampReturnDate(personInfo.admissionTime)" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学校" v-model="personInfo.tenantName" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="层次" v-model="personInfo.eduCategoryName" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学制" v-model="personInfo.learningSystem" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学号" v-model="personInfo.studentNum" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="专业" v-model="personInfo.majorName" readonly  :placeholder="'暂无'"/>
            <van-field class="myfield" label="通讯地址" v-model="personInfo.address" readonly :placeholder="'暂无'" />
          </van-cell-group>
        </div>
      </div>
      <div class="education-area per-area" ref="education">
        <div class="module-head" @click="stretchEvent('edu')">教育经历
          <img class="stretch-btn" v-show="!isShowEdu" src="../../assets/img/toBottom.png" alt="">
          <img class="stretch-btn" v-show="isShowEdu" src="../../assets/img/toTop.png" alt="">
        </div>
        <transition name = "fade">
          <div v-show="isShowEdu">
            <div class="module-cont" v-for="(item, index) in eduData" :key="index">
              <div class="module-cont-title">
                教育经历{{index+1}}
                <div v-if="!onlyRead">
                  <span class="deleteEle" @click="deleteEvent(index, 'edu')">删除</span>
                  <span v-show="eduIndex != index" @click="editEvent(index, 'edu')">编辑</span>
                  <span v-show="eduIndex == index" @click="enterEvent">保存</span>
                </div>
              </div>
              <div class="item-cont">
                <van-form validate-first @failed="onFailed" :readonly="eduIndex != index">
                  <van-field
                    :clickable="eduIndex == index"
                    v-model="item.name"
                    name="用户名"
                    label="学校名称"
                    placeholder="学校名称"
                    required
                    @input="trimLR('name', 'eduData', index)"
                    :rules="[{ required: true, message: '请填写学校名称' }]"
                  />
                  <van-field
                    readonly
                    required
                    :clickable="eduIndex == index"
                    name="datetimePicker"
                    :value="item.startTime"
                    label="开始时间"
                    placeholder="点击选择时间"
                    @click="showTime(index, 'start')"
                  />
                  <van-field
                    readonly
                    required
                    :clickable="eduIndex == index"
                    name="datetimePicker"
                    :value="item.endTime"
                    label="结束时间"
                    placeholder="点击选择时间"
                    @click="showTime(index, 'end')"
                  />
                  <van-field
                    readonly
                    required
                    :clickable="eduIndex == index"
                    name="picker"
                    :value="item.position"
                    label="学历"
                    placeholder="点击选择学历"
                    @click="showPosition(index)"
                  />
                  <van-field
                    :clickable="eduIndex == index"
                    v-model="item.reference"
                    @input="trimLR('reference', 'eduData', index)"
                    name="证明人"
                    label="证明人"
                    placeholder="证明人"
                  />
                </van-form>
              </div>
            </div>
            <div v-if="!onlyRead" class="add-module-area" @click="addEvent('edu')">+ 继续增加教育经历</div>
          </div>
        </transition>
      </div>
      <div class="work-area per-area">
        <div class="module-head" @click="stretchEvent('work')">工作经历 
          <img class="stretch-btn" v-show="!isShowWork" src="../../assets/img/toBottom.png" alt="">
          <img class="stretch-btn" v-show="isShowWork" src="../../assets/img/toTop.png" alt="">
        </div>
        <transition name = "fade">
          <div v-show="isShowWork">
            <div class="module-cont" v-for="(item, index) in workData" :key="index">
              <div class="module-cont-title">
                工作经历{{index+1}}
                <div v-if="!onlyRead">
                  <span class="deleteEle" @click="deleteEvent(index, 'work')">删除</span>
                  <span v-show="workIndex != index" @click="editEvent(index, 'work')">编辑</span>
                  <span v-show="workIndex == index" @click="enterWorkEvent">保存</span>
                </div>
              </div>
              <div class="item-cont">
                <van-form validate-first @failed="onFailed" :readonly="workIndex != index">
                  <van-field
                    :clickable="workIndex == index"
                    v-model="item.name"
                    name="单位名称"
                    label="单位名称"
                    placeholder="单位名称"
                    required
                    @input="trimLR('name', 'workData', index)"
                    :rules="[{ required: true, message: '请填写单位名称' }]"
                  />
                  <van-field
                    readonly
                    required
                    :clickable="workIndex == index"
                    name="datetimePicker"
                    :value="item.startTime"
                    label="开始时间"
                    placeholder="点击选择时间"
                    @click="showTime(index, 'start')"
                  />
                  <van-field
                    :readonly="true"
                    required
                    :clickable="workIndex == index"
                    name="datetimePicker"
                    :value="item.endTime"
                    label="结束时间"
                    placeholder="点击选择时间"
                    @click="showTime(index, 'end')"
                  />
                  <van-field
                    :clickable="workIndex == index"
                    v-model="item.position"
                    name="职务"
                    label="职务"
                    placeholder="职务"
                    required
                    @input="trimLR('position', 'workData', index)"
                    :rules="[{ required: true, message: '请填写职务' }]"
                  />
                  <van-field
                    :clickable="workIndex == index"
                    v-model="item.reference"
                    name="证明人"
                    label="证明人"
                    placeholder="证明人"
                    @input="trimLR('reference', 'workData', index)"
                  />
                </van-form>
              </div>
            </div>
            <div v-if="!onlyRead" class="add-module-area" @click="addEvent('work')">+ 继续增加工作经历</div>
          </div>
        </transition>
      </div>
      <div class="family-area per-area">
        <div class="module-head" @click="stretchEvent('family')">家庭成员 
          <img class="stretch-btn" v-show="!isShowFamily" src="../../assets/img/toBottom.png" alt="">
          <img class="stretch-btn" v-show="isShowFamily" src="../../assets/img/toTop.png" alt="">
        </div>
        <transition name = "fade">
          <div v-show="isShowFamily">
            <div class="module-cont" v-for="(item, index) in familyData" :key="index">
              <div class="module-cont-title">
                家庭成员{{index+1}}
                <div v-if="!onlyRead">
                  <span class="deleteEle" @click="deleteEvent(index, 'family')">删除</span>
                  <span v-show="familyIndex != index" @click="editEvent(index, 'family')">编辑</span>
                  <span v-show="familyIndex == index" @click="enterfamilyEvent">保存</span>
                </div>
              </div>
              <div class="item-cont">
                <van-form validate-first @failed="onFailed" :readonly="familyIndex != index">
                  <van-field
                    :clickable="familyIndex == index"
                    v-model="item.name"
                    name="姓名"
                    label="姓名"
                    placeholder="姓名"
                    required
                    @input="trimLR('name', 'familyData', index)"
                    :rules="[{ required: true, message: '请填写姓名' }]"
                  />
                  <van-field
                    :clickable="familyIndex == index"
                    v-model="item.relation"
                    name="与本人关系"
                    label="与本人关系"
                    placeholder="与本人关系"
                    required
                    @input="trimLR('relation', 'familyData', index)"
                    :rules="[{ required: true, message: '请填写与本人关系' }]"
                  />
                  <van-field
                    :clickable="familyIndex == index"
                    v-model="item.age"
                    type="digit"
                    name="年龄"
                    label="年龄"
                    placeholder="年龄"
                    required
                    maxlength="3"
                    @input="trimLR('age', 'familyData', index)"
                    :rules="[{ required: true, message: '请填写年龄' }]"
                  />
                  <van-field
                    readonly
                    required
                    :clickable="familyIndex == index"
                    name="picker"
                    :value="item.sex"
                    label="性别"
                    placeholder="点击选择性别"
                    @click="showSex(index)"
                  />
                  <van-field
                    :clickable="familyIndex == index"
                    v-model="item.unit"
                    name="工作单位"
                    label="工作单位"
                    placeholder="请输入工作单位"
                    @input="trimLR('unit', 'familyData', index)"
                  />
                  <van-field
                    :clickable="familyIndex == index"
                    v-model="item.phone"
                    type="tel"
                    name="联系方式"
                    label="联系方式"
                    placeholder="请输入联系方式"
                    maxlength="20"
                    @input="trimLR('phone', 'familyData', index)"
                  />
                  <van-field
                    :clickable="familyIndex == index"
                    v-model="item.address"
                    name="住址"
                    label="住址"
                    placeholder="请输入住址"
                    @input="trimLR('address', 'familyData', index)"
                  />
                </van-form>
              </div>
            </div>
            <div v-if="!onlyRead" class="add-module-area" @click="addEvent('family')">+ 继续增加家庭成员</div>
          </div>
        </transition>
      </div>

      <!-- 自定义设置 -->
      <div class="setting-area per-area" ref="setting" v-for="(item, index) in registerInfo.items" :key="index">
        <div class="module-head">
          <div class="infoTitle">{{item.description}}</div>
          <div class="module-head-cont">
            <template v-if="onlyRead">
              {{ item.value }}
            </template>
            <template v-else>
              <van-field
                class="myfield-auto"
                v-model="item.value"
                rows="4"
                autosize
                type="textarea"
                :maxlength="item.wordMax"
                show-word-limit
              />
              <div class="myfieldTips">限制{{ item.wordMin }}-{{ item.wordMax }}字，已写{{ item.value?item.value.length:0 }}字</div>
            </template>
          </div>
        </div>
      </div>
      <!-- 签名 -->
      <div class="signature-area per-area">
        <div class="module-head">
          <div class="module-head-title" :class="{'module-head-title-no':!resultImg}">
            <span><span class="red">*</span>个人签名</span>
            <van-button v-if="!onlyRead" round type="info" class="signature" @click="handleSignature()">点击前往签名</van-button>
          </div>
          <div class="module-head-cont" v-if="resultImg || onlyRead">
            <img class="signature-img" v-if="!onlyRead"  :src="resultImg" alt="">
            <img class="signature-img" v-else  :src="registerInfo.signNameUrl" alt="">
          </div>
        </div>
      </div>
      <van-button round type="info" class="submitBtn" block @click="onSubmit()">{{onlyRead?'返  回':'确认提交'}}</van-button>
    </div>
    <!-- 签名模块 -->
    <div class="signatureWarp" v-if="isShowSignature">
      <div class="signatureBox">
        <vue-esign ref="esign" v-if="windowWidth>windowHeight" :width="windowWidth" :height="windowHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
        <!-- <vue-esign ref="esign"  :width="windowWidth" :height="windowHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" /> -->
      </div>
      <div class="tips"><span class="red">注：</span>请注意书写工整，需要能看清楚字体笔画</div>
      <div class="signatureBtnBox">
        <van-button round  class="signatureBtn" @click="handleSignature()">返回</van-button>
        <van-button round  class="signatureBtn" @click="handleReset()">重写</van-button>
        <van-button round type="info" class="signatureBtn" @click="handleGenerate()">确认保存</van-button>
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showEduPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onEduConfirm"
        @cancel="showEduPicker = false"
      />
    </van-popup>
    <van-popup v-model="showSexPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="sexData"
        @confirm="onfamilyConfirm"
        @cancel="showSexPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import uploadAli from '@/api/uploadAli.js'
import way from '../../api/encapsulation'
import { Dialog,Toast } from "vant";
import vueEsign from 'vue-esign'
export default {
  name: 'graduateRegister',
  components: { vueEsign },
  data() {
    return {
      activeTab:1,
      onlyRead: false,
      isShowEdu: false,
      isShowWork: false,
      isShowFamily: false,
      isShowSignature: false,
      eduData: [],
      eduIndex: -1,
      workData: [],
      workIndex: -1,
      familyData: [],
      familyIndex: -1,
      status: '', // add: 添加, edit: 编辑
      type: '', // start: 开始时间, end: 结束时间
      module: '', // 操作模块
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2030, 11, 31),
      currentDate: new Date(),
      showPicker: false,
      showEduPicker: false,
      showSexPicker: false,
      columns: ["小学", "初中", "高中", "中专", "大专", "本科"],
      sexData: ["男", "女"],

      auditStatus:{
        0:'待审核',
        1:'审核通过',
        2:'审核不通过',
        3:'未提交',
      },
      personInfo:{},
      registerInfo: {},
      // 签名配置
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      isCrop: false,
      windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      if(val) {
        this.queryEduListEvent();
        this.queryWorkList();
        this.queryFamilyList();
        this.getPersonInfo()
        this.getGraduateRegister()
      }
    },
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：",val, that.windowHeight);
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：",val, that.windowWidth);
    }
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight;  // 高
        that.windowWidth = window.fullWidth; // 宽
        console.log(that.windowHeight,'that.windowHeight');
        console.log(that.windowWidth,'that.windowWidth');
      })()
    };
  },
  created() {
    // this.queryEduListEvent();
    //     this.queryWorkList();
    //     this.queryFamilyList();
    //     this.getPersonInfo()
    //     this.getGraduateRegister()
  },
  methods: {
    dateReturnTimestamp: way.dateReturnTimestamp,
    timestampReturnDate: way.timestampReturnDate,
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    // 查询教育经历
    queryEduListEvent() {
      this.$request.queryEduList().then(res => {
        if(res.data.code == 0) {
          res.data.data.forEach(item => {
            item.startTime = this.timestampReturnDate(item.startTime);
            item.endTime = this.timestampReturnDate(item.endTime);
          })
          this.eduData = res.data.data;
        }
      })
    },
    // 查询工作经历
    queryWorkList() {
      this.$request.queryWorkList().then(res => {
        if(res.data.code == 0) {
          res.data.data.forEach(item => {
            item.startTime = this.timestampReturnDate(item.startTime);
            item.endTime = this.timestampReturnDate(item.endTime);
          })
          this.workData = res.data.data;
        }
      })
    },
    // 查询家庭成员
    queryFamilyList() {
      this.$request.queryFamilyList().then(res => {
        if(res.data.code == 0) {
          res.data.data.forEach(item => {
            item.sex = item.sex == '0' ? '男' : '女';
          })
          this.familyData = res.data.data;
        }
      })
    },
    trimLR(name, form = 'eduData', index) {
      this[form][index][name] = way.clearSpace(this[form][index][name]);
    },
    showTime(index, type) {
      if(this.module == 'edu') {
        if(this.eduIndex != index) return;
      } else {
        if(this.workIndex != index) return;
      }
      this.currentDate = new Date();
      this.showPicker = true;
      this.type = type;
    },
    showPosition(index) {
      if(this.eduIndex != index) return;
      this.showEduPicker = true;
      this.eduIndex = index;
    },
    showSex(index) {
      if(this.familyIndex != index) return;
      this.showSexPicker = true;
      this.familyIndex = index;
    },
    onConfirm(time) {
      let newTime = this.dateReturnTimestamp(time);
      let {startTime, endTime} = this.module == 'edu' ? this.eduData[this.eduIndex] : this.module == 'work' ? this.workData[this.workIndex] : this.familyData[this.familyIndex];
      if(this.type == 'start') {
        startTime = newTime;
        endTime = this.dateReturnTimestamp(endTime);
        if(startTime && endTime && startTime > endTime) {
          this.Error('开始时间不能大于结束时间');
          return;
        }
        newTime = this.timestampReturnDate(newTime)
        if(this.module == 'edu')
          this.eduData[this.eduIndex].startTime = newTime;
        else if(this.module == 'work')
          this.workData[this.workIndex].startTime = newTime;
      } else if(this.type == 'end') {
        endTime = newTime;
        startTime = this.dateReturnTimestamp(startTime);
        if(startTime && endTime && startTime > endTime) {
          this.Error('开始时间不能大于结束时间');
          return;
        }
        newTime = this.timestampReturnDate(newTime)
        if(this.module == 'edu')
          this.eduData[this.eduIndex].endTime = newTime;
        else if(this.module == 'work')
          this.workData[this.workIndex].endTime = newTime;
      }
      this.showPicker = false;
    },
    onEduConfirm(value) {
      this.eduData[this.eduIndex].position = value;
      this.showEduPicker = false;
    },
    onfamilyConfirm(value, index) {
      this.familyData[this.familyIndex].sex = value;
      this.showSexPicker = false;
    },
    // 编辑
    editEvent(index, type) {
      let title = type == 'edu' ? '教育经历' : type == 'work' ? '工作经历' : '家庭成员'
      if(this.status == 'add' || this.status == 'edit') {
        this.Error(`请完成当前的操作后，再继续编辑${title}`);
        return;
      }
      this.status = 'edit';
      this.module = type;
      if(type == 'edu') this.eduIndex = index;
      else if(type == 'work') this.workIndex = index;
      else this.familyIndex = index;
    },
    // 保存-教育经历
    enterEvent() {
      if(this.status == 'add') {
        this.addEduEvent();
      } else {
        this.editEduWorkEvent();
      }
    },
    // 保存-工作经历
    enterWorkEvent() {
      if(this.status == 'add') {
        this.addWorkEvent();
      } else {
        this.editEduWorkEvent();
      }
    },
    // 保存-家庭成员
    enterfamilyEvent() {
      if(this.status == 'add') {
        this.addFamilyEvent();
      } else {
        this.editFamilyEvent();
      }
    },
    addEvent(type) {
      let title = type == 'edu' ? '教育经历' : type == 'work' ? '工作经历' : '家庭成员'
      if(this.status == 'add' || this.status == 'edit') {
        this.Error(`请完成当前的操作后，再继续添加${title}`);
        return;
      }
      this.module = type;
      this.status = 'add';
      if(type == 'edu') {
        this.eduIndex = this.eduData.length;
        this.eduData.push({
          name: null,
          position: null,
          startTime: null,
          endTime: null,
          reference: null
        })
      } else if(type == 'work') {
        this.workIndex = this.workData.length;
        this.workData.push({
          name: null,
          startTime: null,
          endTime: null,
          position: null,
          reference: null,
        })
      } else {
        this.familyIndex = this.familyData.length;
        this.familyData.push({
          name: null,
          relation: null,
          age: null,
          sex: null,
          unit: null,
          phone: null,
          address: null
        })
      }
    },
    // 添加教育经历
    addEduEvent() {
      let obj = this.eduData[this.eduIndex];
      if(!obj.name || !obj.position || !obj.startTime || !obj.endTime) {
        this.Error('有必填项为填');
        return;
      }
      obj.startTime = this.dateReturnTimestamp(obj.startTime)
      obj.endTime = this.dateReturnTimestamp(obj.endTime)
      this.$request.addEduList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryEduListEvent();
          this.status = '';
          this.eduIndex = -1;
        }
      })
    },
    // 编辑
    editEduWorkEvent() {
      let obj;
      if(this.module == 'edu') {
        obj = JSON.parse(JSON.stringify(this.eduData[this.eduIndex]));
      } else {
        obj = JSON.parse(JSON.stringify(this.workData[this.workIndex]));
      }
      if(!obj.name || !obj.position || !obj.startTime || !obj.endTime) {
        this.Error('有必填项为填');
        return;
      }
      obj.startTime = this.dateReturnTimestamp(obj.startTime)
      obj.endTime = this.dateReturnTimestamp(obj.endTime)
      this.$request.updateEduList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.status = '';
          this.eduIndex = -1;
          this.workIndex = -1;
        }
      })
      
    },
    // 删除
    deleteEvent(index, type) {
      this.module = type;
      Dialog.confirm({
        title: '删除',
        message: '是否要删除该内容',
      })
      .then(() => {
        // on confirm
        if(type == 'edu') {
          if(this.status == 'add') {
            this.eduData.pop();
            this.eduIndex = -1;
            this.status = '';
            return;
          }
          this.eduIndex = index;
          this.deleteEduWork(type)
        } else if(type == 'work') {
          if(this.status == 'add') {
            this.workData.pop();
            this.workIndex = -1;
            this.status = '';
            return;
          }
          this.workIndex = index;
          this.deleteEduWork(type)
        } else {
          if(this.status == 'add') {
            this.familyData.pop();
            this.familyIndex = -1;
            this.status = '';
            return;
          }
          this.familyIndex = index;
          this.deleteFamily()
        }
      })
      .catch(() => {
        // on cancel
      });
    },
    // 删除教育经历
    deleteEduWork(type) {
      let id = type == 'edu' ? this.eduData[this.eduIndex].id : this.workData[this.workIndex].id;
      this.$request.deleteEduList({id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryEduListEvent();
          this.eduIndex = -1;
          this.workIndex = -1;
        }
      })
    },
    // 添加工作经历
    addWorkEvent() {
      let obj = this.workData[this.workIndex];
      if(!obj.name || !obj.position || !obj.startTime || !obj.endTime) {
        this.Error('有必填项为填');
        return;
      }
      obj.startTime = this.dateReturnTimestamp(obj.startTime)
      obj.endTime = this.dateReturnTimestamp(obj.endTime)
      this.$request.addWorkList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryWorkList();
          this.status = '';
          this.workIndex = -1;
        }
      })
    },
    // 添加家庭成员
    addFamilyEvent() {
      let obj = JSON.parse(JSON.stringify(this.familyData[this.familyIndex]));
      obj.sex = obj.sex == '男' ? 0 : 1;
      if(!obj.name || !obj.relation || !obj.age || !String(obj.sex)) {
        this.Error('有必填项为填');
        return;
      }
      this.$request.addFamilyList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryFamilyList();
          this.status = '';
          this.familyIndex = -1;
        }
      })
    },
    // 删除家庭成员
    deleteFamily() {
      let id = this.familyData[this.familyIndex].id;
      this.$request.deleteFamilyList({id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryFamilyList();
          this.familyIndex = -1;
        }
      })
    },
    // 编辑家庭成员
    editFamilyEvent() {
      let obj = JSON.parse(JSON.stringify(this.familyData[this.familyIndex]));
      obj.sex = obj.sex == '男' ? 0 : 1;
      if(!obj.name || !obj.relation || !obj.age || !String(obj.sex)) {
        this.Error('有必填项为填');
        return;
      }
      this.$request.updateFamilyList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryFamilyList();
          this.familyIndex = -1;
          this.status = '';
        }
      })
    },
    stretchEvent(type) {
      if(type == 'edu')
        this.isShowEdu = !this.isShowEdu;
      else if(type == 'work')
        this.isShowWork = !this.isShowWork;
      else
        this.isShowFamily = !this.isShowFamily;
    },

    handleSignature () {
      let type = this.$store.state.deviceType
      this.isShowSignature = !this.isShowSignature
      if (type === 1) {
        window.webkit.messageHandlers.getData.postMessage('changeScreen()')
      } else if (type === 2) {
        window.Android.changeScreen()
      }
    },
    // 重写签名
    handleReset () {
      this.$refs.esign.reset()
    },
    // 保存签名
    handleGenerate () {
      this.$refs.esign.generate({format: 'image/jpeg', quality: 0.5})
      this.$refs.esign.generate().then(res => {
        this.resultImg = res
        console.log(this.resultImg,'resultImg');
        this.handleReset()
        this.handleSignature()
      }).catch(err => {
        // alert(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    },
    // base64转图片
    getFileFromBase64 (base64URL, filename) {
      let arr = base64URL.split(",")
      console.log(arr,'arr');
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: "image/png" });
    },
    // 学生信息
    getPersonInfo () {
      this.$request.getPersonInfo().then(res => {
        if (res.data.code == 0) {
          console.log('personInfo');
          this.personInfo = res.data.data
        }
      })
    },
    // 获取登记表信息
    getGraduateRegister () {
      this.$request.getGraduateRegister().then(res => {
        if (res.data.code == 0) {
          console.log('getGraduateRegister');
          console.log(res.data.data);
          this.registerInfo = res.data.data
          if (this.registerInfo.auditStatus==0 || this.registerInfo.auditStatus==1) {
            this.onlyRead = true
          }
        }
      })
    },
    async onSubmit () {
      if (!this.onlyRead) {
        for (let i in this.registerInfo.items) {
          let item = this.registerInfo.items[i]
          if (item.wordMin && item.value && item.value.length < item.wordMin || !item.value&& item.wordMin>0) {
            return Toast(item.description+'字数不符');
          }
        }
        if (!this.resultImg) {
          return Toast('还未签名哦');
        }
        let imgtype = await this.getFileFromBase64(this.resultImg, "img.jpeg")
        let img = await uploadAli.uploadOss(imgtype,'img');
        let obj ={
          formerName:this.personInfo.formerName,
          items:this.registerInfo.items,
          signNameUrl:img.excelUrl
        }
        this.$request.postGraduateRegisterSubmit(obj).then(res => {
          if(res.data.code == 0) {
            console.log('返回上一页');
            let type = this.$store.state.deviceType
            if (type === 1) {
              window.webkit.messageHandlers.getData.postMessage('close()')
            } else if (type === 2) {
              window.Android.Close()
            }
          }
        })
      } else {
        let type = this.$store.state.deviceType
        if (type === 1) {
          window.webkit.messageHandlers.getData.postMessage('close()')
        } else if (type === 2) {
          window.Android.Close()
        }
      }
    },
    toModle(tab) {
      this.activeTab = tab
      // 让页面滚动条滑动到底部
      this.$nextTick(() => {
        let container = this.$refs.container
        let { scrollHeight, clientHeight} = container
        if (tab== 1) {
          console.log(scrollHeight,clientHeight);
          container.scrollTop = 0
        } else if (tab == 2) {
          let offsetTop = this.$refs.education.offsetTop
          container.scrollTop = offsetTop-50
        } else if (tab== 3) {
          let offsetTop = this.$refs.setting[0].offsetTop
          container.scrollTop = offsetTop-50
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.signatureWarp {
    position: fixed;
    width: calc(100vw);
    height: calc(100vh);
    z-index: 99;
    top: 0;
    background: #F9F9F9;
    padding: 0;
    overflow: hidden;
    .signatureBox {
      border: 1px solid #CCCCCC;
      height: 65vh;
      background: #FFFFFF;
      border-radius: 15px;
      overflow: hidden;
      margin: 20px 40px 0 40px;
    }
    .tips {
      margin: 5px 40px 10px 40px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3B3B3B;
      line-height: 12px;
    }
    .signatureBtnBox {
      margin: 0 40px 0 40px;
      display: flex;
      justify-content: space-between;
      .signatureBtn {
        width: 30%;
        height: 30px;
      }
    }
}
.submitBtn{
  margin-top: 20px;
}
.red{
  color: #FF0202;
  font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
}
  ::v-deep .mycell-group {
    .myfield:first-child {
      padding-top: 0;
    }
    .myfield:last-child {
      padding-bottom: 0;
    }
  }
  ::v-deep .mycell-group::after {
    border: none;
  }
  ::v-deep .myfield-auto {
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    padding: 10px;
    .van-cell__title {
      display: none;
    }
  }
  ::v-deep .myfield {
    padding-left: 0px;
    padding-right: 0px;
    &::after {
      left: 0;
      right: 0;
      width: 100%;
    }
  }
</style>
<style lang="scss">
  .graduateRegister {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    // padding: 24px;
    background-color: #F9F9F9;
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
        opacity: 0
    }
    .graduateRegisterMain {
      padding: 24px;
      margin-top: 40px;
      .per-area {
        &:not(:first-child) {
          .module-head {
            margin-top: 20px;
          }
        }
        &.signature-area {
          .module-head-title {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
          }
          .module-head-title-no {
            padding-bottom: 0 !important;
            border-bottom: none !important;
          }
        }
        .signature {
          width: 104px;
          height: 30px;
          padding: 0;
        }
        .module-head {
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          background-color: #fff;
          border-radius: 10px;
          position: relative;
          display: flex;
          flex-flow: column;
          .stretch-btn {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
          }
          .infoTitle {
            padding-left: 10px;
            padding-bottom: 17px;
          }
          .module-head-title {
            padding-bottom: 16px;
            border-bottom: 1px solid #F9F9F9;
            margin-bottom: 10px;
          }
          .module-head-cont {
            .head-img {
              width: 100px;
              height: 123px;
              margin: 10px auto 0 auto;
              display: block;
            }
            .signature-img {
              width: 100%;
              height: 123px;
              margin: 10px auto 0 auto;
              display: block;
            }
            .myfieldTips {
              position: absolute;
    right: 20px;
    bottom: 25px;
    background: #FFF;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
            }
          }
        }
        .module-cont {
          margin-top: 10px;
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          .module-cont-title {
            padding: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            // border-bottom: 1px solid #ebedf0;
            display: flex;
            justify-content: space-between;
            .deleteEle {
              color: rgb(255, 43, 43);
              margin-right: 10px;
            }
          }
          .item-cont {
            // padding: 0 16px;
            .item-list-cont {
              padding: 18px 0;
              border-top: 1px solid #F9F9F9;
            }
          }
        }
        .add-module-area {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          padding: 18px 0;
          margin-top: 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 10px;
        }
      }
    }
    .tab {
      width: calc(100% - 42px);
      display: inline-flex;
      justify-content: space-between;
      padding: 0 24px;
    background: #FFFFFF;
    position: fixed;
    z-index: 55;
      .tabItem{
        text-align: center;
        padding: 14px 0;
        font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
line-height: 16px;
        &.active {
          color: #1A7EE2;
          border-bottom: 2px solid #1A7EE2;
        }
      }
    }
  }
</style>